@import "src/styles/helpers";

.modal {
  padding-top: 112px;
  padding-bottom: 0;
}

.menuButton {
  display: none;
  width: 24px;
  height: 24px;
  margin-left: auto;
  transition: all .3s ease;

  &:hover {
    color: var(--primary-800);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @include media(tablet) {
    display: block;
  }

  @include media(mobile) {
    display: block;
  }
}

.closeMenuButton {
  width: 24px;
  height: 24px;

  &:hover {
    color: var(--primary-800);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.menuHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 28px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media(tablet) {
    padding: 16px 64px;
  }

  @include media(mobile) {
    padding: 16px 24px;
  }
}

.logo {

  img {
    display: block;
  }
}

.content {
  height: 100%;
  display: grid;
  align-items: flex-start;

  position: relative;
}

.menu {
  text-align: center;
  display: grid;
  gap: 24px;
  margin-top: -12px;
  padding-top: 48px;
  padding-bottom: 48px;

  &__link {
    padding: 12px 24px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;

    &:hover, &.active {
      color: var(--primary-500);
    }

    &:active, &:focus {
      color: var(--primary-400);
    }
  }
}

.info {
  align-self: flex-end;
  display: grid;
  gap: 12px;
  padding-bottom: 72px;

  .emailLink {
    padding: 12px 0;
    display: inline-block;
    text-align: center;
    color: var(--primary-500);

    &:hover {
      color: var(--primary-400);
    }

    &:active, &:focus {
      color: var(--primary-300);
    }
  }
}

.vector1 {
  position: absolute;
  top: 0;
  right: 300px;

  @include media(mobile) {
    display: none;
  }
}

.vector2 {
  position: absolute;
  top: 200px;
  right: 0;

  @include media(mobile) {
    display: none;
  }
}

.vector3 {
  position: absolute;
  bottom: 0px;
  right: 400px;

  @include media(mobile) {
    right: 50px;
  }
}

.vector4 {
  position: absolute;
  top: 250px;
  left: 200px;

  @include media(mobile) {
    top: unset;
    bottom: 200px;
  }
}