@import "src/styles/helpers";

.button {
  font-weight: bold;
  text-transform: uppercase;
  transition: all .3s ease;
  border-radius: 50px;
  text-align: center;

  &.primary {
    background: var(--primary-button-linear);
    color: var(--primary-10);

    &:hover {
      background: var(--primary-button-linear-hover);
    }

    &:active, &:focus {
      background: var(--primary-button-linear-focus);
    }

    &:disabled, &.disabled {
      background: var(--secondary-200);
      cursor: not-allowed;

      &:hover {
        background: var(--secondary-300);
      }
    }

    &.smallSize {
      padding: 16px;
    }

    &.defaultSize {
      padding: 16px 24px;

      @include media(mobile) {
        padding: 16px;
      }
    }

    &.largeSize {
      padding: 24px 32px;
    }
  }

  &.secondary {
    border: 1px solid var(--primary-500);
    background: transparent;
    color: var(--primary-500);

    &:hover {
      border-color: var(--primary-700);
      color: var(--primary-700);
    }

    &:active, &:focus {
      border-color: var(--primary-500);
      color: var(--primary-500);
    }

    &:disabled, &.disabled {
      border-color: var(--secondary-200);
      color: var(--secondary-200);

      &:hover {
        border-color: var(--secondary-300);
        color: var(--secondary-300);
      }
    }

    &.smallSize {
      padding: 11px;
    }

    &.defaultSize {
      padding: 15px;
    }

    &.largeSize {
      padding: 23px 31px;
    }
  }

  &.text {
    color: var(--primary-500);
    text-decoration: underline;
    text-transform: none;

    &:hover {
      color: var(--primary-700);
    }

    &:focus, &:active {
      color: var(--primary-500);
    }

    &.disabled, &:disabled {
      color: var(--secondary-200);
    }

    &.smallSize {
      padding: 9px 12px;
    }

    &.defaultSize {
      padding: 10px 16px;
    }

    &.largeSize {
      padding: 24px;
    }
  }

  &.smallSize {
    font-size: 12px;
    line-height: 14px;
  }

  &.defaultSize {
    font-size: 14px;
    line-height: 24px;

    @include media(mobile) {

      &:not(.text) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  &.largeSize {
    font-size: 16px;
    line-height: 24px;
  }

  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: transform 0.3s ease;
      margin-left: 12px;
      margin-top: -2px;

      svg, img {
        width: 100%;
        height: 100%;
      }
    }

    &_left {
      flex-direction: row-reverse;

      .icon {
        margin-right: 12px;
        margin-left: 0;
      }
    }
  }
}
