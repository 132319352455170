@import "src/styles/helpers";

.modal {
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: var(--white-bg);
  padding: 120px 0;

  @include media(portrait) {
    background: var(--white-bg);
  }

  @include media(mobile) {
    background: var(--white-bg);
    padding: 56px 0;
  }
}
