@import "src/styles/helpers";

:root {
  --container-padding-mobile: 24px;
  --container-padding-portrait: 40px;
  --container-padding-landscape: 64px;
  --container-padding-desktopSmall: 72px;
  --container-max-width-desktop: 1200px;
  --container-max-width-desktop-big: 1280px;

  @include colors;
}

html {
  //scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

body {
  font-size: 16px;
  line-height: normal;
  background-color: var(--white-bg);
  color: var(--primary-900);
  font-family: 'Gilroy', sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
}

main {
  overflow-x: hidden;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline-color: var(--primary-400);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

pre {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: inherit;
  text-align: inherit;
  cursor: pointer;
  color: inherit;
}

figure {
  margin: initial;
  padding: initial;
}

.container {
  width: 100%;

  @include media(mobile) {
    padding-left: var(--container-padding-mobile) !important;
    padding-right: var(--container-padding-mobile) !important;
  }

  @include media(portrait) {
    padding-left: var(--container-padding-portrait) !important;
    padding-right: var(--container-padding-portrait) !important;
  }

  @include media(landscape) {
    padding-left: var(--container-padding-landscape) !important;
    padding-right: var(--container-padding-landscape) !important;
  }

  @include media(desktopSmall) {
    padding-left: var(--container-padding-desktopSmall) !important;
    padding-right: var(--container-padding-desktopSmall) !important;
  }

  @include media(desktop) {
    width: var(--container-max-width-desktop);
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include media(desktopBig) {
    width: var(--container-max-width-desktop-big);
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.heroTitle {
  color: var(--primary-700);
  font-weight: bold;
  font-size: 50px;
  letter-spacing: -1.5px;

  @include media(landscape) {
    font-size: 40px;
    line-height: 50px;
    max-width: 840px;
    letter-spacing: normal;
  }

  @include media(portrait) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.96px;
  }

  @include media(mobile) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.96px;
  }
}

.title {
  font-weight: bold;
  letter-spacing: -1.2px;
  font-size: 40px;
  line-height: normal;
  color: var(--primary-700);

  @include media(tablet) {
    font-size: 32px;
    letter-spacing: -0.96px;
    line-height: 40px;
  }

  @include media(mobile) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.72px;
  }
}

@include font-face("Gilroy", "/fonts/Gilroy-Regular", 400);
@include font-face("Gilroy", "/fonts/Gilroy-Medium", 500);
@include font-face("Gilroy", "/fonts/Gilroy-Bold", 700);
@include font-face("Gontserrat", "/fonts/Gontserrat-Bold", 700);
