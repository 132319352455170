@mixin colors {
  --primary-10: #F9F9F9;
  --primary-50: #EBF2F9;
  --primary-100: #C4D7ED;
  --primary-200: #9DBCE1;
  --primary-300: #76A1D6;
  --primary-400: #4F87CA;
  --primary-500: #356DB1;
  --primary-600: #295589;
  --primary-650: #001A29; // should be removed
  --primary-700: #1D3C62;
  --primary-750: #0D1D27; // should be removed
  --primary-800: #12253C;
  --primary-850: #041118; // should be removed
  --primary-900: #060C14;

  --secondary-10: #FFF;
  --secondary-50: #EDEDED;
  --secondary-100: #D4D4D4;
  --secondary-200: #BABABA;
  --secondary-300: #A1A1A1;
  --secondary-400: #878787;
  --secondary-500: #6E6E6E;
  --secondary-600: #545454;
  --secondary-700: #3B3B3B;
  --secondary-800: #202020;
  --secondary-900: #050505;

  --error: #D86B7C;

  --linear-hero: linear-gradient(150deg, #34DDFF -19.03%, #5CA1FD 33.57%, #7975FB 63.75%, #8A5AFA 88.3%);

  --primary-button-linear: linear-gradient(145deg, #2DE8FF -25.23%, #34DDFF -18.62%, #5CA1FD 20.72%, #7975FB 54.18%, #8A5AFA 80.1%, #9150FA 95.31%);
  --primary-button-linear-hover: linear-gradient(130deg, #5CA1FD 4.02%, #7975FB 94.7%);
  --primary-button-linear-focus: linear-gradient(143deg, #2DE8FF -3.58%, #34DDFF 1.83%, #5CA1FD 34.04%, #7975FB 61.44%, #8A5AFA 82.65%, #9150FA 95.11%);

  --white-bg: #FFFEFF;
}
