@import "src/styles/helpers";

.title {
  font-size: 50px;
  letter-spacing: -1.5px;
  font-weight: bold;
  color: var(--primary-10);
  margin-bottom: 16px;

  @include media(tablet) {
    font-size: 40px;
    line-height: 50px;
  }

  @include media(mobile) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.96px;
    margin-bottom: 12px;
  }
}

.subtitle {
  line-height: 28px;
  color: var(--secondary-100);

  @include media(tablet) {
    font-size: 14px;
  }

  @include media(mobile) {
    font-size: 14px;
  }
}

.modal {
  padding-bottom: 0;
  background: url('/media/modal_vector.webp') no-repeat bottom right var(--primary-900);
  background-size: 500px;

  footer {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-template-columns: auto 330px;
    gap: 44px;
    padding-bottom: 120px;

    @include media(mobile) {
      grid-template-columns: 1fr;
      gap: 24px;
      padding-bottom: 56px;
    }

    .terms {
      font-size: 12px;
      line-height: 20px;
      color: var(--secondary-200);

      &.error {
        color: var(--error);
      }
    }
  }
}

.form {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;

  :global {
    .MuiInput-input {
      padding-bottom: 12px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }

    .MuiInputBase-root.MuiInput-root {
      padding-bottom: 0 !important;

      &:before {
        border-bottom-color: var(--primary-600) !important;
      }
    }

    .MuiInputLabel-root {
      left: 24px !important;
    }

    .MuiFormHelperText-root.Mui-error {
      position: absolute !important;
      bottom: -22px !important;
      left: 24px !important;
    }
  }
}

.spinner {
  transform-origin: center;
  animation: spin .75s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg)
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.formContent {
  margin-bottom: 48px;
  margin-top: 48px;
  display: grid;
  height: auto;
  align-items: flex-end;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  gap: 60px 40px;

  @include media(mobile) {
    grid-template-columns: 1fr;
    gap: 40px;
    margin-bottom: 64px;
  }
}

.input {

  &.fullWidth {
    grid-column: 1 / 3;

    @include media(mobile) {
      grid-column: initial;
    }
  }

  &.textarea {

    :global {
      .MuiInput-input {
        padding-bottom: 6px !important;
      }
    }
  }
}

.closeButton {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 16px;
  border: 1px solid var(--primary-600);
  transition: color .3s ease;
  position: absolute;
  right: 48px;
  top: 48px;

  @include media(mobile) {
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  &:hover {
    color: var(--primary-200);
  }

  &:focus, &:active {
    color: var(--primary-100);
  }

  &:disabled {
    color: var(--primary-600);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
