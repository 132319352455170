@import 'src/styles/helpers';

.banner {
  height: 64px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-10);
  background-color: var(--primary-500);

  @include media(mobile) {
    flex-direction: column;
    height: 90px;
  }
}

.title {
  display: inline-block;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  @include media(mobile) {
    font-size: 12px;
    line-height: 16px;
  }
}

.iconContainer {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin-left: 12px;

  @include media(mobile) {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
}

.icon {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;

  @include media(mobile) {
    transform: rotate(90deg);
  }
}

.bannerLink {
  margin-left: 12px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  text-decoration: underline;

  @include media(mobile) {
    margin: 12px 0 0 0;
  }
}
