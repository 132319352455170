@import "src/styles/helpers";

.closeButton {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 16px;
  border: 1px solid var(--primary-600);
  transition: color .3s ease;
  position: absolute;
  right: 48px;
  top: 48px;
  z-index: 3;

  @include media(mobile) {
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  &:hover {
    color: var(--primary-200);
  }

  &:focus, &:active {
    color: var(--primary-100);
  }

  &:disabled {
    color: var(--primary-600);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.title {
  background-color: var(--primary-200);
  background-image: var(--linear-hero);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 80px;
  line-height: 98%;
  font-weight: bold;
  letter-spacing: -2.4px;
  text-transform: uppercase;
  margin-bottom: 24px;

  @include media(tablet) {
    font-size: 50px;
    letter-spacing: -1.5px;
  }

  @include media(mobile) {
    font-size: 50px;
    letter-spacing: -1.5px;
  }
}

.subtitle {
  margin: 0 auto 48px;
  line-height: 28px;
  color: var(--secondary-100);
}

.button {
  margin: 0 auto;

  @include media(mobile) {
    width: 100%;
  }
}

.wrapper {
  height: 100%;
  display: grid;
  align-items: flex-start;
  position: relative;
  overflow-y: auto;
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  position: relative;

  @include media(mobile) {
    padding: 80px 24px 56px;
  }
}

.modal {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden !important;
}

.vector {
  position: absolute;
  right: -400px;
  bottom: -390px;
  width: 681px;
  height: 681px;
  transform: rotate(-19deg);
  opacity: 0.5;

  img {
    width: 100%;
    height: 100%;
  }

  @include media(desktopBig) {
    right: -70px;
    bottom: -250px;
  }

  @include media(desktopSmall) {
    bottom: -300px;
  }

  @include media(landscape) {
    right: -560px;
  }

  @include media(portrait) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.vector2 {
  position: absolute;
  width: 566px;
  height: 566px;
  transform: rotate(164deg);
  opacity: 0.5;
  left: -426px;
  top: 200px;

  img {
    width: 100%;
    height: 100%;
  }

  @include media(desktopSmall) {
    top: 100px;
  }

  @include media(tablet) {
    top: 100px;
  }

  @include media(mobile) {
    top: 100px;
  }
}
