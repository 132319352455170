@import "src/styles/helpers";

.header {
  padding: 28px 80px;
  font-size: 14px;

  @include media(tablet) {
    padding: 16px 64px;
  }

  @include media(mobile) {
    padding: 16px 24px;
  }
}

.content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 48px;

  @include media(tablet) {
    grid-template-columns: auto auto;
  }

  @include media(mobile) {
    grid-template-columns: auto auto;
  }
}

.menu {
  display: flex;
  justify-content: flex-start;
  gap: 48px;
  text-transform: uppercase;

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }

  &__link {
    color: var(--primary-800);
    transition: color .3s ease;
    padding: 12px 0;

    &:hover, &.active {
      color: var(--primary-900);
    }

    &:focus, &:active {
      color: var(--primary-900);
    }
  }
}

.info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 48px;

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.logo {
  transition: transform 0.3s ease;

  @include media(tablet) {
    transform: none !important;
  }

  @include media(mobile) {
    transform: none !important;
  }

  img {
    display: block;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.emailLink {
  font-size: 15px;
  color: var(--primary-500);
  transition: color .3s ease;

  &:hover {
    color: var(--primary-600);
  }

  &:active, &:focus {
    color: var(--primary-700);
  }
}
